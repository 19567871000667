import React from "react";

const NotFound = () => {
  return (
    <div className="centered-div m-section">
      <h2>Ingenting her...</h2>
      <p>Vi fant ingenting på denne siden</p>
    </div>
  );
};

export default NotFound;
